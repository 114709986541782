import jQuery from 'jquery';
import moment from 'moment';
//--------------------------------------------------
// Filters
jQuery(function ($) {

	$('.ui.dropdown').dropdown();

	$('.ui.calendar').calendar({
    	type: 'month',
		startMode: 'month',
		firstDayOfWeek: 1,
		formatter: {
			date: function(date, settings) {
				let momentDate = new moment(date);
				return momentDate.format('YYYY-MM');
			}
		}
	});

});
